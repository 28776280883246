export default {
  Modal: {
    delete: {
      cant_undo_message: 'Esta acción no se puede deshacer',
      field_delete_confirmation:
        '¿Está seguro de que desea eliminar este lote?',
      sowing_delete_confirmation:
        '¿Está seguro de que desea eliminar esta siembra?',
    },
    standard: {
      cancel: 'Cancelar',
    },
  },
}
