export default {
  Components: {
    input: {
      numbers_only: 'Numbers only',
    },
    filter_select: {
      results_not_found: 'Results not found',
    },
    date_picker: {
      cancel: 'Cancel',
      choose: 'Choose',
    },
    select_dropdown: {
      clean: 'Clean',
      apply: 'Apply',
      results_not_found: 'Results not found',
    },
  },
}
