export default {
  Components: {
    input: {
      numbers_only: 'Sólo números',
    },
    filter_select: {
      results_not_found: 'Resultados no encontrados',
    },
    date_picker: {
      cancel: 'Cancelar',
      choose: 'Elegir',
    },
    select_dropdown: {
      clean: 'Limpiar',
      apply: 'Aplicar',
      results_not_found: 'Resultados no encontrados',
    },
  },
}
